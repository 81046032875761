<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.3327 9.00002L13.9993 5.66669V8.16669H7.33268V9.83335H13.9993V12.3334L17.3327 9.00002ZM15.666 14C14.6166 15.3992 13.1535 16.4328 11.4841 16.9543C9.81462 17.4758 8.02339 17.4588 6.36412 16.9057C4.70485 16.3526 3.26167 15.2915 2.239 13.8726C1.21633 12.4537 0.666016 10.749 0.666016 9.00002C0.666016 7.251 1.21633 5.54631 2.239 4.12743C3.26167 2.70856 4.70485 1.64742 6.36412 1.09433C8.02339 0.541237 9.81462 0.52424 11.4841 1.04574C13.1535 1.56724 14.6166 2.6008 15.666 4.00002H13.391C12.4288 3.15141 11.2421 2.59847 9.97341 2.40757C8.70471 2.21666 7.40787 2.3959 6.23851 2.92377C5.06915 3.45164 4.07695 4.30571 3.38096 5.38351C2.68497 6.4613 2.31476 7.71704 2.31476 9.00002C2.31476 10.283 2.68497 11.5387 3.38096 12.6165C4.07695 13.6943 5.06915 14.5484 6.23851 15.0763C7.40787 15.6041 8.70471 15.7834 9.97341 15.5925C11.2421 15.4016 12.4288 14.8486 13.391 14H15.666Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconLogout",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
